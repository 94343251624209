<template>
  <b-card-actions
    action-collapse
    collapsed
    class="text-capitalize"
  >
    <template #title>
      <b-row
        class="text-nowrap mb-1 mb-md-0"
      >
        <b-col>
          <div
            class="mb-1"
          >
            Topic : <span>{{ session.topic }}</span>
          </div>
          <div
            class="mb-1"
          >
            Start Time : <span>{{ $moment(session.start_time).format('YYYY-MM-DD HH:mm') }}</span>
          </div>
        </b-col>
        <b-col>
          <div
            class="mb-1"
          >
            Speaker : <span>{{ session.speaker }}</span>
          </div>
          <div>
            End Time : <span>{{ $moment(session.end_time).format('YYYY-MM-DD HH:mm') }}</span>
          </div>
        </b-col>
      </b-row>
    </template>
    <b-card-text>
      <b-form-row>
        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            <feather-icon
              icon="LinkIcon"
              class="mr-50"
              size="20"
            />
            url
          </div>
          <b-link
            :href="session.url"
            target="_blank"
          >
            {{ session.url }}
          </b-link>
        </b-col>

        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            <font-awesome-icon
              icon="fa-solid fa-venus-mars"
              size="lg"
              class="mr-50"
            />
            target gender
          </div>
          <span>{{ session.target_gender }}</span>
        </b-col>

        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            <font-awesome-icon
              icon="fa-solid fa-user-group"
              size="lg"
              class="mr-50"
            />
            Registered Count
          </div>
          <span>{{ session.registeredCount }}</span>
        </b-col>

        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            <font-awesome-icon
              icon="fa-solid fa-user-group"
              size="lg"
              class="mr-50"
            />
            presence type
          </div>
          <span>{{ event.presence_type }}</span>
        </b-col>

        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            <feather-icon
              icon="GlobeIcon"
              class="mr-50"
              size="20"
            />
            language
          </div>
          <span>{{ session.language }}</span>
        </b-col>

        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            <font-awesome-icon
              icon="fa-solid fa-user-group"
              size="lg"
              class="mr-50"
            />
            capacity
          </div>
          <span>{{ session.capacity }}</span>
        </b-col>

        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            <font-awesome-icon
              icon="fa-solid fa-syringe"
              size="lg"
              class="mr-50"
            />
            require Vaccination
          </div>
          <span>{{ session.vaccination_required ? 'Yes' : 'No' }}</span>
        </b-col>

        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            <feather-icon
              icon="DollarSignIcon"
              class="mr-50"
              size="20"
            />
            price
          </div>
          <span>{{ session.price || 'Free' }}</span>
        </b-col>

        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
              size="20"
            />
            ticket type
          </div>
          <span>{{ session.paid }}</span>
        </b-col>

        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            <feather-icon
              icon="LinkIcon"
              class="mr-50"
              size="20"
            />
            status
          </div>
          <span>{{ session.status ? 'Active' : 'Inactive' }}</span>
        </b-col>

        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            <font-awesome-icon
              icon="fa-solid fa-road"
              size="lg"
              class="mr-50"
            />
            venue
          </div>
          <span>{{ session.venue }}</span>
        </b-col>
        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            Names Required
          </div>
          <span>{{ session.attendees_name_required ? 'Yes' : 'No' }}</span>
        </b-col>
        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            Auto Approval
          </div>
          <span>{{ session.auto_approval ? 'Yes' : 'No' }}</span>
        </b-col>
        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            Max Female
          </div>
          <span>{{ session.max_female_number }}</span>
        </b-col>
        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            Max Male
          </div>
          <span>{{ session.max_male_number }}</span>
        </b-col>
        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            payment method
          </div>
          <span>{{ session.payment_method }}</span>
        </b-col>
        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            target gender
          </div>
          <span>{{ session.target_gender }}</span>
        </b-col>
        <b-col
          md="3"
          class="mb-3"
        >
          <div class="info-name">
            tax ratio
          </div>
          <span>{{ session.tax_ratio }}%</span>
        </b-col>

        <b-col
          cols="12"
          class="mb-2 border p-2"
        >
          <div class="info-name">
            restriction notes
          </div>
          <ol v-if="session.restriction_notes.length">
            <li
              v-for="note in session.restriction_notes"
              :key="note.id"
              class="mb-1"
            >{{ note.note }}</li>
          </ol>
          <span v-else>no notes found</span>
        </b-col>

        <b-col
          cols="12"
          class="mb-2 border p-2"
        >
          <div class="info-name">
            additional services
          </div>
          <ol v-if="session.additional_services.length">
            <li
              v-for="service in session.additional_services"
              :key="service.id"
              class="mb-1"
            >
              <span class="mr-3">Title: {{ service.title }}</span>
              <span>Price: {{ service.price }}</span>
            </li>
          </ol>
          <span v-else>no services found</span>
        </b-col>

        <b-col
          cols="12"
          class="mb-3 border p-2"
        >
          <div class="info-name">
            age segments
          </div>
          <ol v-if="session.age_segments.length">
            <li
              v-for="segment in session.age_segments"
              :key="segment.id"
              class="mb-1"
            >
              <span class="mr-3">from: {{ segment.from }}</span>
              <span class="mr-3">To: {{ segment.to }}</span>
              <span>Capacity: {{ segment.capacity }}</span>
            </li>
          </ol>
          <span v-else>no age segments found</span>
        </b-col>
      </b-form-row>

      <b-row align-h="center">
        <b-button
          v-if="$can('show','BookingRequestController')"
          variant="primary"
          class="mr-1"
          :to="{name:'quick-event-session-bookings',params:{ eventId:$route.params.eventId,id:session.id } }"
        >
          <feather-icon
            icon="FileTextIcon"
            class="mr-50"
          />
          View Bookings
        </b-button>
        <b-button
          v-if="$can('update','EventController') && $moment().isBefore(session.end_time)"
          variant="primary"
          class="mr-1"
          :to="{name:'quick-event-session-edit',params:{ eventId:$route.params.eventId,id:session.id } }"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          Edit
        </b-button>
        <b-button
          v-if="$can('store','EventController') && !event.singleSession"
          variant="primary"
          class="mr-1"
          :to="{name:'quick-event-session-duplicate',params:{ eventId:$route.params.eventId,id:session.id } }"
        >
          <feather-icon
            icon="CopyIcon"
            class="mr-50"
          />
          Duplicate
        </b-button>
        <b-button
          v-if="$can('delete','EventController')"
          variant="outline-danger"
          @click="deleteSession(session.id,deleteMessage)"
        >
          <feather-icon
            icon="TrashIcon"
            class="mr-50"
          />
          Delete
        </b-button>
      </b-row>
    </b-card-text>
  </b-card-actions>
</template>
<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  name: 'SessionCard',
  components: {
    BCardActions,
  },
  props: {
    session: { type: Object, default: () => {} },
    event: { type: Object, default: () => {} },
    deleteSession: { type: Function, default: () => {} },
  },
  computed: {
    deleteMessage() {
      return this.session.registeredCount ? 'Session has active booked users, Do you need to delete' : null
    },
  },
}
</script>
<style lang="scss">
.info-name{
margin-bottom: .8em;
font-weight: 500;
}
</style>
