<template>
  <div>
    <div
      v-if="$moment().isBefore(event.end_date)&&$can('store','EventController')&&!event.singleSession"
      class="text-right mb-1"
    >
      <b-button
        :to="{ name: 'quick-event-session-add' , params:{eventId:$route.params.eventId} }"
        variant="primary"
      >
        <span class="text-nowrap">Add Session</span>
      </b-button>
    </div>

    <spinner-loader :loading="loading" />

    <div
      v-for="session in sessions"
      :key="session.id"
    >
      <session-card
        :session="session"
        :event="event"
        :delete-session="deleteSession"
      />
    </div>

    <b-card
      v-if="!sessionsExist"
      class="text-center"
    >
      No Sessions Found
    </b-card>

    <div>
      <back />
    </div>
  </div>
</template>
<script>
import SessionCard from '@/common/components/QuickEvents/Sessions/SessionCard.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/FormInputs/Back.vue'

export default {
  name: 'SessionsList',
  components: {
    SessionCard,
    SpinnerLoader,
    Back,
  },
  data() {
    return {
      sessions: [],
      event: {},
      loading: true,
      deleteEndPoint: `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/events/${this.$route.params.eventId}/sessions/`,
    }
  },
  computed: {
    sessionsExist() {
      return !this.loading && this.sessions.length
    },
  },
  setup() {
    const {

      successfulDeletionAlert,
      confirmOperation,
    } = handleAlerts()

    return {

      successfulDeletionAlert,
      confirmOperation,
    }
  },
  created() {
    this.getSessions()
  },
  methods: {
    getSessions() {
      this.$activities.get(`/internalops/events/${this.$route.params.eventId}/sessions`).then(res => {
        this.sessions = res.data.data.sessions
        this.event = res.data.data.event
      }).finally(() => {
        this.loading = false
      })
    },
    deleteSessionRequest(id) {
      return this.$activities.delete(`${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/events/${this.$route.params.eventId}/sessions/${id}`, {
        headers: {
          entityId: this.$store.getters['mainEntity/getEntityId'],
        },
      }).then(() => {
        this.successfulDeletionAlert()
        this.deleteSessionById(id)
      })
    },
    deleteSession(id, message = null) {
      return this.confirmOperation(message).then(() => this.deleteSessionRequest(id))
    },
    deleteSessionById(id) {
      const sessionIndex = this.sessions.findIndex(session => session.id === id)
      this.sessions.splice(sessionIndex, 1)
    },
  },
}
</script>
<style lang="scss">
.info-name {
margin-bottom: .8em;
font-weight: 500;
}
</style>
